import React from 'react';
import { ThemeProvider } from '@zion-tech/ds-web';
import { zion } from '@zion-tech/ds-tokens';
import { GlobalStyle } from '@components/index';
import Alerts from '@shared/alerts';
import Router from '@pages/Router';
import './app.css';

/* Tracking Web Application */
import { Segment, Sentry } from '@shared/tracking';
import usePageTracking from '@shared/tracking/usePageTracking';
import googleAnalyticsInit from '@shared/tracking/google-analytics';

const customZionTheme = {
  ...zion,
  global: {
    ...zion?.global,
    colors: {
      ...zion?.global?.colors,
      'background': {
        dark: '#09101c',
        light: '#09101c',
      },
      'background-back': {
        dark: '#0b1626',
        light: '#EFEFEF',
      },
      'background-front': {
        dark: '#0b1626',
        light: '#FFFFFF',
      },
      'background-contrast': {
        dark: '#0b1626',
        light: '#0000000A',
      },
      'background-darker': {
        dark: '#0b1626',
        light: '#ccc',
      },
    },
  },
};

function App() {
  googleAnalyticsInit();
  usePageTracking();
  return (
    <ThemeProvider theme={{ ...customZionTheme }}>
      <Segment />
      <GlobalStyle />
      <Router />
      <Alerts />
    </ThemeProvider>
  );
}

/*
text: {
  dark: string;
  light: string;
},
brand: string;
brand2: string;
brand3: string;
brand4: string;
neutral1: string;
neutral2: string;
neutral3: string;
neutral4: string;
neutral5: string;
background: {
  dark: string;
  light: string;
};
'background-back': {
  dark: string;
  light: string;
};
'background-front': {
  dark: string;
  light: string;
};
'background-contrast': {
  dark: string;
  light: string;
};
'background-darker': {
  dark: string;
  light: string;
};
white: {
  dark: string;
  light: string;
};
blue: {
  dark: string;
  light: string;
};
green: {
  dark: string;
  light: string;
};
teal: {
  dark: string;
  light: string;
};
purple: {
  dark: string;
  light: string;
};
red: {
  dark: string;
  light: string;
};
orange: {
  dark: string;
  light: string;
};
yellow: {
  dark: string;
  light: string;
};
'white!': string;
'blue!': string;
'green!': string;
'teal!': string;
'yellow!': string;
'purple!': string;
'red!': string;
'orange!': string
*/

export default Sentry.withProfiler(App, { name: 'ZionOnline' });
