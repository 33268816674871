import React, { useState, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { VariableSizeList as List, areEqual } from 'react-window';
import { IPlan, IPlanGoal, IPlanGoalItem } from '@models/Plan/Plan.model';
import { getSelectedPlan } from '@models/Plan/Plan.state';
import { PlanGoalFollowup, PlanGoalContent } from '@models/Plan/Plan.service';
import { IResponseEnum } from '@shared/api/Api.model';
import Skeleton from '@components/Skeleton/Skeleton';
import { getBibleRef } from '@shared/bible/bible.utils';
import { getFullDateNow } from '@shared/date';
import { showAlert } from '@shared/alerts';
import {
  Container,
  Goal,
  GoalItems,
  GoalItem,
  Badge,
  GoalDetail,
  GoalHeader,
  GoalDateText,
} from './GoalsList.style';
import CelebrationModal from '../Modals/CelebrationModal';
import Check from '../Check/Check';
import GoalDetailModal from '../Modals/GoalDetailModal';
import GoalDetailIcon from './GoalDetailIcon';
import FullPlanCompletedModal from '../Modals/FullPlanCompletedModal';

const GoalsList = ({ ...props }, ref) => {
  const { t: translate, i18n } = useTranslation();
  const plan = useSelector(getSelectedPlan);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenFullPlanCompleted, setModalOpenFullPlanCompleted] = useState(false);
  const [isModalOpenGoalDetail, setModalOpenGoalDetail] = useState<boolean>(false);
  const [goalContent, setGoalContent] = useState([]);
  const [goalItemsContent, setGoalItemsContent] = useState<IPlanGoalItem[]>([]);

  const SkeletonPlanGoals = memo(() => (
    <Container>
      <Goal style={{ marginTop: 120 }}>
        <Skeleton loading width='100%' height='68px' />
      </Goal>
      <Goal style={{ marginTop: 0 }}>
        <Skeleton loading width='100%' height='68px' />
      </Goal>
      <Goal style={{ marginTop: 0 }}>
        <Skeleton loading width='100%' height='68px' />
      </Goal>
      <Goal style={{ marginTop: 0 }}>
        <Skeleton loading width='100%' height='68px' />
      </Goal>
    </Container>
  ));

  SkeletonPlanGoals.displayName = 'SkeletonPlanGoals';

  const handleToogleDetailGoal = useCallback(async (id: string, goal: IPlanGoal) => {
    const { language } = i18n;

    try {
      const response = await PlanGoalContent(id, language.toLocaleLowerCase());

      if (response.status.type === IResponseEnum.success && response.data.length > 0) {
        setGoalContent(response.data);
        setGoalItemsContent(goal.goalItems);
        setModalOpenGoalDetail(!isModalOpenGoalDetail);
      }

      if (response.status.type === IResponseEnum.error) {
        showAlert({ type: 'error', message: translate(response.status.message) });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: translate('Ocorreu uma falha, tente novamente mais tarde'),
      });
    }
  }, []);

  const PlanGoalsList = memo(
    ({ data, index, style }: { data?: any; index?: number; style?: any }) => {
      const { goals }: IPlan = data;
      const goal = goals[index];
      const now: boolean =
        data?.strategy === 'date' && goal?.fullDate?.doy === getFullDateNow().doy;

      return (
        <Goal id={data?.strategy === 'date' ? goal?.fullDate?.doy : goal?.order} style={style}>
          <GoalHeader>
            <GoalDateText color='text' size='small' weight={800} now={now}>
              {data?.strategy === 'date' ? goal.fullDate.display : goal.order}
            </GoalDateText>
            {now && <Badge>Hoje</Badge>}
            <GoalDetail onClick={() => handleToogleDetailGoal(goal.id, goal)}>
              <GoalDetailIcon />
            </GoalDetail>
          </GoalHeader>
          <GoalItems>
            {goal?.goalItems &&
              goal.goalItems.map((goalItem: IPlanGoalItem) => (
                <GoalItem key={`goalitem-${goalItem.id}`}>
                  <Check
                    id={goalItem.id}
                    name={goalItem.id}
                    checked={goalItem.completed}
                    label={mountGoalItemName(goalItem?.title)}
                    onCheck={value => handleFollowup(goal, { ...goalItem, completed: value })}
                  />
                </GoalItem>
              ))}
          </GoalItems>
        </Goal>
      );
    },
    areEqual,
  );

  PlanGoalsList.displayName = 'PlanGoalsList';

  const handleFollowup = async (goal: IPlanGoal, goalItem: IPlanGoalItem) => {
    if (goal && goalItem) {
      const response = await PlanGoalFollowup(goalItem.id, goalItem.completed);
      if (response.status.type !== IResponseEnum.success) {
        // do nothing
      } else if (response?.data?.planHasJustBeenCompleted) {
        setModalOpenFullPlanCompleted(true);
      } else if (response?.data?.goal?.completed) {
        setModalOpen(true);
      }
    }
  };

  const handleFinishFollowupGoals = useCallback(async () => {
    const listGoalItems = goalItemsContent.map(item => {
      return PlanGoalFollowup(item.id, true);
    });
    try {
      const response = await Promise.all(listGoalItems);

      const success = response.map(
        item => item.status.type === IResponseEnum.success && item.data.goal.completed,
      ).length;

      const planHasJustBeenCompleted = response.find(
        item => item.data.planHasJustBeenCompleted === true,
      );

      if (success === goalItemsContent.length) {
        setGoalContent([]);
        setGoalItemsContent([]);
        setModalOpenGoalDetail(false);

        if (planHasJustBeenCompleted) {
          setModalOpenFullPlanCompleted(true);
        } else {
          setModalOpen(true);
        }
      }

      const error = response.filter(item => item.status.type === IResponseEnum.error).length;

      if (error) {
        showAlert({
          type: 'error',
          message: translate('Ocorreu uma falha ao marcar a meta como concluída'),
        });
      }
    } catch (error) {
      showAlert({
        type: 'error',
        message: translate('Ocorreu uma falha, tente novamente mais tarde'),
      });
    }
  }, [goalItemsContent]);

  const handleCloseCelebrationModal = () => {
    setModalOpen(false);
    window.location.reload();
  };

  const handleCloseFullPlanCompletedModal = () => {
    setModalOpenFullPlanCompleted(false);
    window.location.reload();
  };

  const mountGoalItemName = (name: string) => {
    if (plan && plan?.planType === 'bible') {
      const bibleRef = getBibleRef(name);
      return translate(`${bibleRef?.prefix}${bibleRef?.book}`, { value: bibleRef?.reference });
    }
    return name;
  };

  const goalItemsHeight = (index: number) => {
    switch (plan?.goals[index].goalItems.length) {
      case 1:
        return 160;
      case 2:
        return 220;
      case 3:
        return 260;
      case 4:
        return 320;
      case 5:
        return 380;
      default:
        return 450;
    }
  };

  return (
    <>
      {plan && plan?.goals && (
        <Container {...props}>
          <List
            ref={ref}
            width='100%'
            height={window.innerHeight}
            itemData={plan}
            itemSize={(index: number) => goalItemsHeight(index)}
            itemCount={plan?.goals?.length}
            style={{ marginTop: 100, scrollBehavior: 'smooth' }}>
            {PlanGoalsList}
          </List>
        </Container>
      )}
      {!plan?.goals && <SkeletonPlanGoals />}
      <CelebrationModal onClose={() => handleCloseCelebrationModal()} isOpen={isModalOpen} />
      <FullPlanCompletedModal
        onClose={() => handleCloseFullPlanCompletedModal()}
        isOpen={isModalOpenFullPlanCompleted}
      />
      {isModalOpenGoalDetail && (
        <GoalDetailModal
          onFinish={handleFinishFollowupGoals}
          onClose={() => setModalOpenGoalDetail(false)}
          data={goalContent}
        />
      )}
    </>
  );
};

export default React.forwardRef(GoalsList);
