import { pixelToRem } from '@shared/styles/pxToRem';
import styled from 'styled-components';
import { Text } from '@zion-tech/ds-web';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  list-style: none;
  overflow: hidden;
  padding-top: 57px;
  margin-top: 0;
  scroll-behavior: smooth;
`;

export const Goal = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
`;

export const GoalItems = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
`;

export const GoalItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 10px 0 18px;

  .goal-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
`;

export const Badge = styled.div`
  font-weight: bold;
  width: max-content;
  padding: 0px ${pixelToRem(12)} 0px ${pixelToRem(12)};
  border-radius: ${pixelToRem(8)};
  font-size: ${pixelToRem(12)};
  background: #fff;
  color: #0e1319;
`;

export const GoalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${pixelToRem(4)};
  border-bottom: 1px solid #172c4c;
  padding-bottom: ${pixelToRem(16)};

  & span {
    padding: 0;
  }
`;

export const GoalDetail = styled.button`
  background: transparent;
  width: ${pixelToRem(32)};
  height: ${pixelToRem(32)};
  cursor: pointer;
  border: none;
  color: #ffffff;
  font-size: ${pixelToRem(24)};
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: end;
`;

export const GoalDateText = styled(Text)`
  ${(props: { now: boolean }) =>
    props.now &&
    `max-width: ${pixelToRem(235)};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `}
`;
