import styled from 'styled-components';

export const PlanOrderSelector = styled.div`
  position: fixed;
  top: 60px;
  width: 100%;
  background: #0c1625;
  padding: 20px 0;
  border-bottom: 1px solid #172c4c;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
`;
