import styled from 'styled-components';

export const PageSection = styled.section`
  padding: 20px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
`;

export const FeaturedCard = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: space-between;
  gap: 18px;

  width: 100%;
  padding: 24px;
  background: #003535;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  border: 1px solid #003535;

  span {
    color: #fff;
    background: -webkit-linear-gradient(90deg, #a0e0de 0%, #dafdb8 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    stroke: #dafdb8;
  }
`;

export const ShortcutSlider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  position: relative;
  right: 24px;
  width: 101vw;
  overflow-x: scroll;
  padding-left: 24px;
  padding-right: 24px;
  text-decoration: none;

  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  a {
    display: block;
    text-decoration: none;
    color: #fff;
  }
`;

export const Shortcut = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;

  width: 142px;
  height: 120px;
  padding: 24px;
  background: #0c1625;
  border: 1px solid #172c4c;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  border: 1px solid #172c4c;
  text-decoration: none;
`;

export const PlansSlider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 18px;
  position: relative;
  right: 24px;
  width: 101vw;
  overflow-x: scroll;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 8px;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const PlanCard = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 18px;

  width: 100%;
  height: 220px;
  padding: 24px;
  background: #1a212c;
  border: 1px solid #09101c;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 10%);
  border: 2px solid #09101c;

  ${props =>
    props.thumbnail
      ? `
        background-image: url(${props.thumbnail});
        background-size: cover;
      `
      : ''}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -2px;
    height: 100%;
    width: 100%;
    background: #09101c;
    opacity: 0.9;
    transition: 0.4s;
    border-radius: 14px;
    border: 2px solid #09101c;
  }

  span {
    position: relative;
    z-index: 1;
  }

  /*
  &:after {
    content: '';
    background: linear-gradient(
      to bottom,
      rgb(26 33 45 / 75%) 0%,
      rgb(26 33 45 / 86%) 60%,
      rgb(26 33 45) 100%
    );
    background-blend-mode: multiply;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    border-radius: 15px;
  }

  span {
    position: relative;
    z-index: 1;
  }
  */
`;
