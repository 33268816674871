import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Text, Button } from '@zion-tech/ds-web';
import { Location } from 'grommet-icons';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlansService } from '@models/Plan/Plan.service';
import { IResponseEnum } from '@shared/api/Api.model';
import { getUser } from '@models/User/User.state';
import { getPlansList, setPlans, setSelectedPlan } from '@models/Plan/Plan.state';
import { IPlan } from '@models/Plan/Plan.model';

import { getName } from '@shared/naming';
import { Header, Page } from '@components/index';
import Skeleton from '@components/Skeleton';
import {
  PageSection,
  FeaturedCard,
  PlanCard,
  Shortcut,
  ShortcutSlider,
  PlansSlider,
} from './Home.style';
import { GroupIcon, MoneyIcon, QRCodeIcon, StudyIcon } from './components';

const Home = ({ title }) => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispach = useDispatch();
  const user = useSelector(getUser);
  const plans = useSelector(getPlansList);

  useEffect(() => {
    document.title = title;
    getAllPlans();
  }, []);

  const handleGroupsClick = () => {
    navigate(`/links`, { replace: false });
  };

  const handleShortcutClick = (url, external) => {
    if (external) window.location.href = url;
    else navigate(url, { replace: false });
  };

  const handlePlanClick = (selectedPlan: IPlan) => {
    dispach(setSelectedPlan(selectedPlan));
    navigate(`/plans/${selectedPlan.slug}`, { replace: false });
  };

  const getAllPlans = async () => {
    if (!plans) {
      const plansResponse = await PlansService();
      if (plansResponse.status.type === IResponseEnum.success && plansResponse?.plans) {
        dispach(setPlans(plansResponse?.plans));
      }
    }
  };

  const shortcuts = [
    {
      name: translate('Contribuir'),
      icon: <MoneyIcon />,
      click: () =>
        handleShortcutClick(
          'https://compartilhar.sistemaprover.com.br/contribuicao/share-planos?ti=c7d0b84a3e74a81fe807cfc19275f9c8&tu=ee67c69262824a0e&tt=3',
          true,
        ),
    },
    {
      name: translate('Check-in'),
      icon: <QRCodeIcon />,
      click: () => handleShortcutClick('/checkin-types', false),
    },
    {
      name: translate('ZAO'),
      icon: <StudyIcon />,
      click: () => handleShortcutClick('https://zionchurch.org.br/zao', true),
    },
    {
      name: translate('Seja um Voluntário'),
      icon: <GroupIcon />,
      click: () => handleShortcutClick('https://zionchurch.org.br/areas-executivas', true),
    },
  ];

  return (
    <>
      <Header fixed />
      <Page fixedHeader fluid>
        <>
          <PageSection style={{ marginBottom: 0 }}>
            <Skeleton loading={!user?.data?.name.toString()} width='522px' height='42px'>
              <Text size='xlarge' weight='bolder' style={{ textTransform: 'capitalize' }}>
                {translate('Olá')}, {getName(user?.data?.name).first}! 👋
              </Text>
            </Skeleton>
          </PageSection>
          <PageSection>
            <FeaturedCard>
              <Text size='medium' weight='bolder' color='#1f2836'>
                {translate('Faça parte de um LINK')}
              </Text>
              <Text size='xsmall' color='#1f2836'>
                {translate(
                  'Os LINKs são grupos de conexão e discipulado que se reúnem como família nos lares.',
                )}
              </Text>
              <Button
                rounded
                icon={<Location color='#1f2836' />}
                variant='ghost'
                label={translate('ver no mapa')}
                style={{ padding: 0, color: '#1f2836' }}
                onClick={() => handleGroupsClick()}
              />
            </FeaturedCard>
          </PageSection>

          {plans && plans.filter(plan => !plan.isCompleted).length && (
            <PageSection style={{ paddingTop: 0 }}>
              <Text size='medium' weight='bolder' color='#fff'>
                {translate('Planos')}
              </Text>
              <PlansSlider>
                {plans
                  .filter(plan => !plan.isCompleted)
                  .map((plan: IPlan) => (
                    <PlanCard
                      key={`plan-${plan.id}`}
                      thumbnail={plan.thumbnail}
                      onClick={() => handlePlanClick(plan)}>
                      <div className='overlay' />
                      <Text size='small' weight='bolder'>
                        {translate(plan.name)}
                      </Text>
                    </PlanCard>
                  ))}
              </PlansSlider>
            </PageSection>
          )}
          {plans && plans.filter(plan => plan.isCompleted).length > 0 && (
            <PageSection style={{ paddingTop: 0 }}>
              <Text size='medium' weight='bolder' color='#fff'>
                {translate('Planos Concluídos')}
              </Text>
              <PlansSlider>
                {plans
                  .filter(plan => plan.isCompleted)
                  .map((plan: IPlan) => (
                    <PlanCard
                      key={`plan-${plan.id}`}
                      thumbnail={plan.thumbnail}
                      onClick={() => handlePlanClick(plan)}>
                      <div className='overlay' />
                      <Text size='small' weight='bolder'>
                        {translate(plan.name)}
                      </Text>
                    </PlanCard>
                  ))}
              </PlansSlider>
            </PageSection>
          )}
          <PageSection>
            <Text size='medium' weight='bolder' color='#fff'>
              {translate('Explorar')}
            </Text>
            <ShortcutSlider>
              {shortcuts &&
                shortcuts.map(shortcut => (
                  <Shortcut key={shortcut.name} onClick={() => shortcut.click()}>
                    {shortcut.icon}
                    <Text size='xsmall' weight='bolder'>
                      {shortcut.name}
                    </Text>
                  </Shortcut>
                ))}
            </ShortcutSlider>
          </PageSection>
        </>
      </Page>
    </>
  );
};

export default Home;
