import { Button, Text } from '@zion-tech/ds-web';
import styled from 'styled-components';
import { pixelToRem } from '@shared/styles/pxToRem';

export const Header = styled.div`
  display: inherit;
  justify-content: space-between;
`;

export const GroupButton = styled.div`
  display: flex;
  align-items: center;
  gap: ${pixelToRem(8)};
`;

export const AccessibilityFontButton = styled(Button)`
  font-size: ${pixelToRem(12)};
  padding: ${pixelToRem(4)} ${pixelToRem(8)};
  min-width: ${pixelToRem(36)};
  min-height: ${pixelToRem(36)};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${pixelToRem(10)} ${pixelToRem(34)} ${pixelToRem(10)};
  gap: ${pixelToRem(12)};
  width: 100%;
  padding: ${pixelToRem(24)} ${pixelToRem(16)};
`;

export const Content = styled.div`
  height: ${(props: { height: number }) => pixelToRem(props.height)};
  overflow: hidden auto;
`;

export const Versicules = styled.ul`
  list-style: none;
  padding-left: 10px;
  margin: ${pixelToRem(15)} 0;
`;

export const VersiculesItem = styled.li`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${pixelToRem(10)};
  gap: ${pixelToRem(12)};

  & span {
    font-size: ${(props: { size: number }) => pixelToRem(props.size || 16)};
  }
`;

export const ButtonCompleteContent = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export const WrapperPlayer = styled.div`
  border-top: ${pixelToRem(1)} solid #263040;
  padding-top: ${pixelToRem(10)};

  & audio {
    width: 100%;
  }

  & span {
    margin-bottom: ${pixelToRem(12)};
    display: flex;
    align-items: center;
    gap: ${pixelToRem(12)};
  }
`;

export const WrapperBible = styled.div``;

export const BibleTitle = styled(Text)`
  display: inline-block;
  padding-bottom: 30px;
  font-family: 'Playfair Display', serif;
  font-weight: bold;
  font-size: 40px;
  font-style: italic;
  color: #fff;
  background: -webkit-linear-gradient(90deg, #a0e0de 0%, #dafdb8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const BibleVersesText = styled(Text)`
  font-family: 'Faculty Glyphic', serif;
  font-optical-sizing: auto;
  padding-bottom: 20px;
`;

export const GroupNumberVersicules = styled.div`
  display: flex;
  flex-direction: column;
  font-size: ${(props: { size: number }) => pixelToRem(props.size || 16)};

  & span:first-child {
    border-bottom: 1px solid;
  }
`;

export const ButtonClose = styled.button`
  border: none;
  background: none;
  color: #ffffff;
  display: flex;
  justify-content: flex-start;
  font-size: ${pixelToRem(24)};
  align-items: center;
  gap: ${pixelToRem(8)};
  cursor: pointer;
`;
